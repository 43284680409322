/*!
	Theme Name: WordPress Theme 1096
	Theme URI: http://template-help.com/
	Description: A theme from Template-Help.com Collection
	Version: 3
	Author: Template_Help.com 
	Author URL: http://www.Template-Help.com/ 
*/
@import url(https://fonts.googleapis.com/css?family=Jockey+One);
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.logos-sprite,
.amazon a,
.camping-world a,
.deseret-book a,
.fun-again a,
.toys-r-us a {
  background-image: url("images/logos-s842c0ee1f4.png");
  background-repeat: no-repeat;
}
body {
  font: 14px/20px Arial, sans-serif;
  color: #fff;
}
html {
  background: #003f5f url(images/bg.jpg) no-repeat center 0;
}
.container-width,
#footer-notes {
  width: 967px;
  margin: 0 auto;
}
#content {
  clear: both;
  background: url(images/content-bg-ver.png) repeat-x 0 0;
  margin: 0px 0px 0px 0px;
  padding: 13px 0px 40px 0px;
}
.content-bg {
  background: url(images/content-bg2.png) repeat 0 0;
  padding: 21px 0px 5px 0px;
  min-height: 300px;
}
.home .content-bg {
  background: url(images/content-bg.png) repeat-y 0 0;
}
#primary {
  overflow: hidden;
  height: 100%;
  padding: 0px 20px 0px 0px;
}
.search #sidebar {
  float: left;
  width: 140px;
  padding: 5px 10px;
}
.search #primary {
  float: left;
  padding: 0 10px;
  width: 780px;
}
.page #primary {
  padding: 0 20px;
}
.home #primary {
  padding: 0px 0px 0px 0px;
}
#primary .column1 {
  float: left;
  width: 280px;
  padding: 0px 9px 10px 32px;
}
#primary .column2 {
  float: left;
  width: 282px;
  padding: 0px 21px 10px 20px;
}
#primary .column3 {
  overflow: hidden;
  height: 100%;
  padding: 0px 10px 10px 31px;
}
.twtr-widget .twtr-tweet {
  border-bottom: 1px solid #193f52 !important;
}
.page #sidebar {
  float: left;
  width: 200px;
  margin: 6px 25px 0px 20px;
  color: #fff;
  display: none;
}
.sidebar-narrow {
  width: 140px;
}
#footer {
  clear: both;
  padding: 10px 0px 5px 2px;
}
.rounded {
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  -khtml-border-radius: 5px;
  border-radius: 5px;
}
.hidden {
  display: none;
}
.alignleft {
  float: left;
}
.alignright {
  float: right;
}
.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
  clear: both;
}
#header {
  height: 107px;
  padding: 15px 23px 0px 0px;
  position: relative;
}
#logo {
  float: left;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  padding: 5px;
  -moz-border-radius: 0 0 8px 8px;
  -webkit-border-radius: 0 0 8px 8px;
  border-radius: 0 0 8px 8px;
}
#logo,
#logo a {
  font-size: 28px;
  line-height: 30px;
  color: #aa2029;
  font-weight: bold;
}
#logo a {
  height: 100px;
  width: 102px;
  display: block;
}
#logo a:hover {
  text-decoration: none;
  color: #020100;
}
#descriprion {
  display: none;
}
#faded {
  width: 964px;
  position: relative;
  height: 340px;
  margin: 10px 0;
  background: url(images/slider-bg.png) no-repeat -3px 0;
}
.thumb-img {
  float: left;
  width: 530px;
  background: white;
  margin: 20px 66px 0px 45px;
  display: flex;
  justify-content: center;
}
.thumb-img * {
  margin: 0;
  padding: 0;
}
#faded a.prev,
#faded a.next {
  display: block;
  width: 45px;
  height: 71px;
  position: absolute;
  z-index: 1;
  top: 114px;
  overflow: hidden;
  text-indent: -1000em;
}
#faded a.prev {
  background: url(images/left.png) no-repeat 0 0;
}
#faded a.next {
  background: url(images/right.png) no-repeat 0 0;
  left: 570px;
}
#faded a.prev:hover,
#faded a.next:hover {
  background-position: bottom;
}
.block-text {
  overflow: hidden;
  height: 100%;
  padding: 4px 0 0;
  font-size: 14px;
  color: #fff;
  line-height: 18px;
  position: absolute;
  top: 0;
  right: -310px;
  width: 318px;
}
.block-text h2 {
  font-size: 48px;
  line-height: 48px;
  word-wrap: break-word;
  font-family: "Jockey One", cursive;
  text-transform: uppercase;
  color: #fff;
  padding: 0px 0px 19px 0px;
}
.block-text p {
  margin: 0px 0px 18px 0px;
}
.block-text p span {
  display: block;
  color: #88bfff;
  padding: 0 100px 0 0;
  margin: 0;
}
#gamelist li {
  padding: 10px 0;
  border-bottom: 1px solid #193f52;
  overflow: auto;
  clear: both;
  background: none;
}
#gamelist li p a.btn_buy,
.block-text p a.more {
  display: block;
  width: 122px;
  height: 40px;
  float: left;
  clear: both;
  margin: 5px 0px 0px 0px;
  text-align: center;
  line-height: 41px;
  background: url(images/more-bg.gif) repeat-x 0 0;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  text-decoration: none;
}
#gamelist li p a.btn_buy {
  float: none;
  margin: 10px 0;
}
#gamelist li p a.btn_buy:hover,
.block-text p a:hover {
  color: #000;
}
#gamelist li > p {
  overflow: auto;
}
a {
  text-decoration: none;
  color: #88bfff;
}
a:hover {
  color: #fff;
  text-decoration: underline;
}
a img {
  border: none;
}
.more-link {
  display: block;
  clear: both;
  font-size: 18px;
  color: #aa2029;
  text-transform: uppercase;
  text-decoration: none;
}
.more-link:hover {
  text-decoration: none;
}
p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form,
.gallery-row,
.post-thumb,
.post-thumb-single,
.entry-meta {
  margin-bottom: 20px;
}
.navigation-links,
#featured,
.pinglist {
  margin-bottom: 40px;
}
.hentry {
  margin-bottom: 10px;
  clear: both;
}
.widget .children,
.entry-content ol ol,
.entry-content ul ul,
.widget ul ul {
  margin-bottom: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: Arial, Sans-serif;
  color: #fff;
}
h1 {
  font-size: 28px;
  font-weight: normal;
  line-height: 32px;
  padding-bottom: 15px;
  padding-left: 1px;
}
h2 {
  font-size: 20px;
  line-height: 22px;
  padding-bottom: 15px;
}
h3 {
  font-size: 16px;
  line-height: 20px;
  padding-bottom: 15px;
}
h4 {
  font-size: 14px;
  line-height: 18px;
  padding-bottom: 10px;
}
h5 {
  font-size: 12px;
  line-height: 18px;
  padding-bottom: 5px;
}
h6 {
  font-size: 12px;
  line-height: 18px;
}
.page-title,
h1 {
  font-weight: normal;
  font-size: 28px;
  font-family: "Jockey One", cursive;
  line-height: 32px;
  color: #fff;
  padding: 5px 0px 18px 0px;
  margin: 0px 0px 0px -1px;
}
#primary .entry-title,
#primary .entry-title a {
  font-size: 14px;
  font-weight: normal;
  font-family: "Jockey One", cursive;
  line-height: 1em;
  color: #88bfff;
  border: none;
  padding: 0;
}
#primary .entry-title a:hover {
  color: #fff;
  text-decoration: none;
}
#primary .entry-title {
  padding: 0px 0px 2px 0px;
}
#primary h1.entry-title {
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  color: #fff;
  padding: 5px 0px 18px 0px;
  margin: 0px 0px 0px -1px;
}
.not-found .entry-content #s {
  width: 180px;
  padding: 3px 0px 4px 5px;
  line-height: 11px;
  float: left;
  margin: 0px 4px 0px 0px;
}
.not-found .entry-content #searchsubmit {
  width: 67px;
  float: left;
  height: 24px;
  overflow: hidden;
  text-indent: -1000em;
  border: 0;
  background: url(images/search2.png) no-repeat 0 0;
  cursor: pointer;
}
.post {
  padding: 0px 0px 30px 0px !important;
}
.column-entry {
  overflow: hidden;
  height: 100%;
}
.published {
  display: block;
  text-transform: lowercase;
  font-size: 10px;
  color: #a68585;
  line-height: 1.1em;
  padding: 5px 0px 4px 0px;
}
.entry-meta {
  font-size: 14px;
  color: #88bfff;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  overflow: hidden;
}
.entry-meta a {
  color: #88bfff;
}
.entry-meta a:hover {
  color: #fff !important;
}
.post-edit-link {
  font-size: 14px;
  display: none;
  text-decoration: underline;
  color: #88bfff;
  padding: 0px 0px 0px 0px;
}
.meta-sep {
  padding: 0 1px;
  color: #d2d2d2;
}
.home .hentry {
  margin: 0px 0px -2px 0px;
  border: none;
}
.page .hentry {
  padding: 0;
  border: none;
}
.comments-link {
  float: left;
  width: 35px;
  height: 36px;
  text-align: center;
  line-height: 32px;
  margin: 6px 16px 0px 0px;
  background: url(images/comments.png) no-repeat 0 0;
}
.comments-link a {
  display: block;
  font-size: 10px;
  color: #fff;
  text-decoration: none;
}
.entry-content {
  clear: both;
  line-height: 1.3em;
  padding: 0;
}
.entry-summary {
  clear: both;
  line-height: 1.3em;
  padding: 0px 15px 0px 0px;
}
.entry-content p {
  line-height: 22px;
}
.entry-content p span {
  color: #88bfff;
}
.columns {
  width: 100%;
  overflow: hidden;
}
.columns .column-left,
.columns .column-right {
  width: 45%;
  float: left;
}
.columns .column-right {
  margin-left: 10px;
}
.img-indent {
  float: left;
  margin: 20px 20px 6px 0px;
}
.wrapper {
  clear: both;
  overflow: hidden;
  height: 100%;
}
.list-left {
  float: left;
  width: 46%;
  margin: 0px 0px 0px 0px;
}
.post .post-thumb img,
.post-thumb-single img {
  padding: 4px;
  background: #fff;
  border: 1px solid #ddd;
  -moz-box-shadow: 0 0 3px rgba(153, 153, 153, 0.25);
  -webkit-box-shadow: 0 0 3px rgba(153, 153, 153, 0.25);
  box-shadow: 0 0 3px rgba(153, 153, 153, 0.25);
}
.post .post-thumb a {
  border: none !important;
}
.post .post-thumb img:hover {
  background: #333;
}
.post .wp-post-image {
  border: 2px solid #fff;
}
address {
  font-style: italic;
}
abbr[title],
acronym[title],
dfn[title] {
  cursor: help;
  border-bottom: 1px dotted #666;
}
blockquote {
  font-family: Cambria, Georgia, "Times New Roman", Times, serif;
  color: #666;
  font-style: italic;
  border-left: 1px solid #ddd;
  margin-left: 15px;
  padding-left: 10px;
}
strong {
  font-weight: bold;
}
em,
dfn,
cite {
  font-style: italic;
}
dfn {
  font-weight: bold;
}
sup {
  font-size: 11px;
  vertical-align: top;
}
sub {
  font-size: 11px;
  vertical-align: bottom;
}
small {
  font-size: 11px;
}
del {
  text-decoration: line-through;
}
ins {
  text-decoration: underline;
}
code,
pre {
  line-height: 18px;
}
var,
kbd,
samp,
code,
pre {
  font: 11px/13px Arial, Helvetica, sans-serif;
  background: #591a1a;
}
kbd {
  font-weight: bold;
}
samp,
var {
  font-style: italic;
}
pre {
  white-space: pre;
  overflow: auto;
  padding: 10px;
  border: 1px solid #ddd;
  clear: both;
}
code {
  padding: 3px;
}
ul,
ol,
dl {
  margin-left: 30px;
}
.entry-content ul {
  margin: 0px 0px 10px 5px;
  list-style: none;
}
.entry-content ul li {
  background: url(images/marker.gif) no-repeat 0 7px;
  padding: 0px 0px 6px 15px;
}
table {
  border: 1px solid #ccc;
  border-width: 1px;
  line-height: 18px;
  margin: 0 0 22px 0;
  text-align: left;
  padding: 0 5px;
}
table .even {
  background: #ddd;
}
caption {
  text-align: left;
}
tr {
  border-bottom: 1px solid #ccc;
}
th,
td {
  padding: 5px;
  vertical-align: middle;
  text-align: center;
}
img.alignleft {
  float: left;
  margin: 3px 26px 15px 7px;
  padding: 2px;
  background: #005a89;
  border: 1px solid #0173a9;
}
img.alignright {
  display: block;
  margin: 5px 0 15px 15px;
  padding: 2px;
  background: #005a89;
  border: 1px solid #0173a9;
}
img.aligncenter {
  margin-left: auto;
  margin-right: auto;
  display: block;
  clear: both;
  margin-top: 5px;
  padding: 2px;
  background: #005a89;
  border: 1px solid #0173a9;
}
.wp-caption {
  text-align: center;
  margin: 15px;
  font-family: Georgia, serif;
  font-style: italic;
}
.wp-caption img,
.wp-caption img a {
  margin: 0;
  padding: 0;
  border: 0 none;
}
.wp-caption .wp-caption-text {
  margin: 0;
  padding: 5px;
}
.wp-smiley {
  max-height: 13px;
  margin: 0 !important;
}
.website-thumbnail img {
  width: 100px !important;
  height: 100px !important;
}
.gallery {
  margin: auto;
}
.gallery-image {
  border: 2px solid #efefef;
  margin-left: 0;
}
.gallery-caption {
  margin-left: 0;
}
.gallery-item {
  float: left;
  text-align: center;
  margin-left: 0;
}
.gallery-columns-2 {
  width: 50%;
}
.gallery-columns-3 {
  width: 33%;
}
.gallery-columns-4 {
  width: 25%;
}
.gallery-columns-5 {
  width: 20%;
}
.gallery-columns-6 {
  width: 16%;
}
.gallery-columns-7 {
  width: 14%;
}
.gallery-columns-8 {
  width: 12%;
}
.gallery-columns-9 {
  width: 11%;
}
#comments {
  clear: both;
  padding: 20px 0px 0px 0px;
}
.alt {
  clear: both;
  padding-bottom: 20px;
  font-weight: normal;
}
.alt a {
  padding: 0px 0px 0px 0px;
  text-decoration: underline;
}
.alt a:hover {
  padding: 0px 0px 0px 0px;
  text-decoration: none;
}
.commentlist {
  color: #fff;
  text-align: justify;
  margin: 0px 0px 15px 0px;
}
.commentlist a {
  color: #88bfff;
}
.commentlist span {
  color: #fff;
  font-weight: bold;
}
.commentlist li {
  list-style: none;
  font-size: 12px;
  overflow: hidden;
  height: 100%;
  margin: 6px 0px 6px 0px;
  padding: 5px 10px 8px;
  list-style: none;
  border: 1px solid #410000;
  color: #fff;
  background: #591a1a;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}
.commentlist .comment-reply-link {
  color: #ff0000;
  float: right;
}
.commentlist li .avatar {
  float: right;
  border: 1px solid #fff;
  padding: 2px;
  background: #fff;
}
.commentlist p {
  margin: 10px 5px 10px 0;
  color: #fff;
}
#commentform p {
  margin: 5px 0;
}
#commentform input,
#commentform textarea {
  display: block;
  margin: 2px 0px 4px 0px;
}
#commentform textarea {
  width: 90%;
}
#commentform #submit {
  width: 128px;
  height: 50px;
  background: url(images/submit.png) no-repeat 0 0;
  border: 0;
  overflow: hidden;
  text-indent: -1000em;
}
#commentform #submit:hover {
  padding: 0px 0px 0px 0px;
}
.nocomments {
  text-align: center;
  margin: 0;
  padding: 0;
  display: none;
}
.commentmetadata {
  margin: 0;
  display: block;
}
span.quote_left {
  display: inline;
  margin: 15px 15px 10px 0;
  padding: 0 10px 0 0;
  border-right: 3px solid #e8e8e8;
  font: 14px/22px Arial, serif;
  text-align: right;
  font-style: italic;
  color: #666;
}
span.quote_right {
  display: inline;
  margin: 15px 0 10px 15px;
  padding: 0 0 0 10px;
  border-left: 3px solid #e8e8e8;
  font: 14px/22px Arial, serif;
  font-style: italic;
  color: #666;
}
blockquote {
  margin: 0 30px 15px;
  padding: 0 0 0 10px;
  border-left: 3px solid #e8e8e8;
  font: 14px/22px Arial, serif;
  font-style: italic;
  color: #666;
}
#navigation {
  float: right;
  margin: -20px 0px 0px 0px;
}
#navigation li {
  float: left;
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background: none;
}
#navigation li button {
  border: 0 !important;
  display: block;
  width: 24px !important;
  height: 24px !important;
  margin: 0px 0px 0px 10px;
}
#navigation li button#one_a {
  background: url(images/m_list.png) no-repeat 0 0;
}
#navigation li button#two_a {
  background: url(images/m_block.png) no-repeat 0 0;
}
#navigation li button:hover {
  background-position: 0 bottom !important;
}
#content-slider {
  clear: both;
}
#content-slider li {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
  background: none !important;
}
.content-slider-inside {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
#visitsite,
#visitsite2 {
  background: url(images/bg2.gif) repeat-x 0 0;
  float: left;
  text-align: center;
  width: 67px;
  height: 24px;
  line-height: 23px;
}
#visitsite a,
#visitsite2 a {
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
  padding: 0px 0px 0px 0px;
}
#visitsite a:hover,
#visitsite2 a:hover {
  color: #000 !important;
}
.website-name a {
  color: #fff !important;
}
.website-right {
  overflow: hidden;
  height: 100%;
  padding: 0px 0px 10px 0px;
}
.single .entry-content,
.page .entry-content {
  margin-bottom: 0;
}
.single .hentry,
.page .hentry {
  border: none;
  padding: 0;
  margin: 0;
}
.single-resources #primary {
  padding-left: 30px;
}
.single-faded #primary {
  padding-left: 30px;
}
.list {
  margin: 6px 0px 0px 1px;
  line-height: 1.3em;
}
.list a {
  font-size: 14px;
  display: block;
  color: #fff;
  padding: 10px 20px 10px 40px;
  text-decoration: none;
  background: url(images/list_thumb.png) no-repeat left center;
  border-bottom: 1px solid #193f52;
  min-height: 30px;
  cursor: default;
}
.list a:hover {
  color: #88bfff;
}
#sharing {
  float: right;
  clear: right;
  padding: 75px 0 0 0;
}
#sharing .widgettitle {
  display: none;
}
#sharing li {
  list-style-type: none;
}
#sharing a {
  display: block;
  float: left;
  text-decoration: none;
  margin: 0px 0px 0px 6px;
  position: relative;
  height: 32px;
  width: 32px;
}
.navigation {
  clear: both;
  margin: 0px 0px 0px 0px;
  padding: 0px 20px 0px 0px;
}
.navigation a {
  display: block;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
}
.navigation a:hover {
  text-decoration: none;
  color: #d93f20;
}
.navigation .nav-next {
  float: right;
  width: 45%;
  text-align: right;
}
.navigation .nav-previous {
  float: left;
  width: 45%;
}
#nav-below {
  padding: 14px 20px 14px 0px;
}
.widget {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 14px 0px;
}
.widget-title {
  font-weight: normal;
  font-size: 28px;
  line-height: 32px;
  color: #fff;
  padding: 0px 0px 18px 0px;
  margin: 0px 0px 0px -1px;
}
.widget ul {
  border: medium none;
  margin: 0px 0px 0px 4px;
  padding: 0;
}
.widget ul li {
  list-style: none outside none;
  background: url(images/marker.gif) no-repeat 0 6px;
  padding: 0px 0 8px 12px;
  margin: 0;
}
.widget a {
  color: #fff;
  font-size: 14px;
  text-decoration: none;
}
.widget a:hover {
  text-decoration: underline;
  color: #88bfff;
}
.widget_calendar caption {
  margin-bottom: 10px;
}
.widget_calendar table {
  width: 100%;
  margin: 0;
  border: 1px solid #ca2e17;
}
.widget_calendar table thead {
  background: #450000;
}
.widget_calendar table tfooter {
  background: #ca2e17;
}
.widget_calendar table td#today {
  background: #380000;
}
.widget_calendar table tr {
  border-bottom: 1px solid #ca2e17;
}
.widget_calendar table td {
  border-right: 1px solid #ca2e17;
  background: #8b1818;
}
.widget_calendar table td.pad {
  background: #5e0000;
}
.widget_calendar table td a {
  color: #380000 !important;
}
.widget_calendar table td a:hover {
  color: #920a0a;
}
.xoxo {
  padding: 0px 0px 0px 0px;
  margin: 0px 0px 0px 0px;
}
.xoxo,
.widget ul {
  list-style-type: none;
}
.widget_search {
  margin-bottom: 30px;
  width: 300px;
  height: 30px;
}
.widget_search form {
  margin: 0;
}
.widget_search #s {
  padding: 3px;
  width: 150px;
  margin: 0px 0px 4px 0px;
}
.widget_search #searchsubmit {
  display: block;
  clear: both;
  overflow: hidden;
  text-indent: -1000em;
  width: 67px;
  height: 24px;
  background: url(images/search2.png) no-repeat;
  border: none;
  padding: 0;
}
.widget_tag_cloud div {
  padding: 0px 0px 0px 0px;
}
.widget_tag_cloud div a {
  line-height: 1.6em;
  font-size: 14px;
}
.widget_text .textwidget {
  line-height: 1.3em;
  color: #fff;
}
.widget_text .textwidget p {
  margin: 0px 0px 18px 0px;
}
.widget_text .textwidget p span {
  color: #88bfff;
}
.widget-button {
  height: 33px;
  background: url(images/more-line.gif) repeat-x 0 0;
}
#widget-button {
  position: relative;
  background: #450000;
  width: 104px;
  padding: 0px 10px 0px 0px;
  height: 39px;
  float: right;
  margin: 0;
  text-align: left;
  line-height: 39px;
  display: none;
}
#widget-button a {
  font-size: 10px;
  color: #fff;
  display: block;
  text-transform: uppercase;
  text-align: center;
  text-decoration: none;
  background: url(images/more2.gif) no-repeat right 17px;
}
#widget-button a.close {
  background: url(images/more.gif) no-repeat right 17px;
}
#widget-button a:hover {
  text-decoration: underline;
}
#footer-widget-area {
  display: block;
  clear: both;
  padding: 27px 0px 5px 32px;
}
#footer-widget-area .widget-area {
  float: left;
}
#footer-widget-area #second {
  margin: 0px 113px 0px 118px;
}
#footer-widget-area .widget-title {
  padding: 0px 0px 24px 0px;
}
#footer {
  color: #fff;
  clear: both;
}
#footer a {
  color: #88bfff;
  text-decoration: underline;
}
#footer a:hover {
  color: #fff;
  text-decoration: none;
}
#footer-notes {
  font-size: 14px;
}
#footer-notes a {
  color: #88bfff;
  text-decoration: none;
}
#footer-notes a:hover {
  color: #fff;
  text-decoration: underline;
}
.archive-lists .children {
  margin-bottom: 0;
}
.full-width {
  width: 880px !important;
}
.wp-pagenavi a,
.wp-pagenavi a:link,
.wp-pagenavi a:visited,
.wp-pagenavi a:active {
  padding: 4px 8px;
  margin: 2px;
  text-decoration: none;
  border: 1px solid #ddd !important;
  background: #ffffff;
  color: #999;
}
.wp-pagenavi a:hover {
  border: 1px solid #39c !important;
  color: #39c !important;
}
.wp-pagenavi span.pages {
  padding: 4px 8px;
  margin: 2px;
  font-style: italic;
  color: #999;
  font-size: 11px;
}
.wp-pagenavi span.current {
  padding: 4px 8px;
  margin: 2px;
  font-weight: bold;
  border: 1px solid #3399cc;
  background: #3399cc;
  color: #fff;
}
.wp-pagenavi span.extend {
  padding: 4px 8px;
  margin: 2px;
  border: 1px solid #ddd;
  background-color: #ffffff;
}
.nav-previous {
  float: left;
}
.nav-next {
  float: right;
}
.safari .widget_search #s,
.safari #commentform input,
.safari #commentform textarea,
.safari .contactform li input,
.safari .contactform li textarea,
.chrome .widget_search #s,
.chrome #commentform input,
.chrome #commentform textarea,
.chrome .contactform li input,
.chrome .contactform li textarea {
  padding: 4px;
}
.game {
  clear: both;
}
.game-wrap {
  background: #fff;
  text-align: center;
  padding: 10px 0;
}
.game-spec {
  display: inline-block;
  margin-right: 15px;
  padding-left: 10px;
  width: 40%;
}
ul.vendors li {
  background: none;
  float: left;
  width: 235px;
  padding: 0 20px 20px 0;
  position: relative;
  top: 0;
}
ul.vendors li:hover {
  top: -2px;
}
.game-description {
  display: inline-block;
  vertical-align: top;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  padding-right: 10px;
  width: 70%;
}
.game-downloads {
  display: inline-block;
  vertical-align: top;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  border-left: 1px solid #fff;
  min-height: 100px;
  padding-left: 15px;
  width: 25%;
}
.game-tag {
  font-family: "Jockey One", cursive;
  font-size: 21px;
}
.entry-content .tab-navigation {
  float: left;
  font-size: 21px;
  line-height: 2;
  margin: 0 0 20px;
  text-align: center;
  width: 100%;
}
.entry-content .tab-navigation a {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=50);
  opacity: 0.5;
  border-bottom: 1px solid transparent;
  color: #fff;
  width: 100%;
  display: block;
}
.entry-content .tab-navigation a:hover {
  text-decoration: none;
}
.entry-content .tab-navigation li {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border-bottom: 1px solid #fff;
  background: none;
  float: left;
  font-family: "Jockey One", cursive;
  font-size: 24px;
  padding: 0;
  width: 25%;
}
.entry-content .tab-navigation .ui-state-active {
  background: rgba(0, 0, 0, 0.3);
  border-color: #fff;
}
.entry-content .tab-navigation .ui-state-active a {
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  filter: progid:DXImageTransform.Microsoft.Alpha(enabled=false);
  opacity: 1;
  border-color: #fff;
  color: #fff;
}
.ui-tabs-hide {
  display: none;
}
#primary {
  overflow: visible;
  height: auto;
}
.vendors a {
  background-color: white;
  border: 10px solid white;
  border-width: 10px 5px;
  display: block;
  height: 40px;
  text-indent: -9999px;
  width: 235px;
}
.amazon a {
  background-position: 0 0;
}
.camping-world a {
  background-position: 0 -40px;
}
.deseret-book a {
  background-position: 0 -80px;
}
.fun-again a {
  background-position: 0 -120px;
}
.toys-r-us a {
  background-position: 0 -160px;
}
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}
.clear {
  clear: both;
}
.clearfix {
  display: inline-block;
}
.clearfix {
  display: block;
}
.none {
  display: none;
}
