form {
    max-width: 500px;
}

.form-row {
    margin: 14px 0;

    label {
        display: block;
        margin: 2px 0;
    }

    input, textarea {
        width: 100%;
    }
}

input, textarea {
    line-height: 1.5;
    font-size: 16px;
    padding: 4px;
    background: white;
}

button {
    width: auto;
    padding: 8px 14px;
    font-size: 14px;
    cursor: pointer;
}