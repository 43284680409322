#nav-main {
    width: 100%;
    margin: 10px 0 20px 0;

    ul {
        display: flex;
        justify-content: stretch;
        margin: 0;
        padding: 0;
        width: 100%;
    }

    li {
        height: 100px;
        color: white;
        list-style-type: none;
        margin: 0;
        padding: 0;
        flex: 1;
        margin-top: 0;
        transition: margin-top .2s ease-in-out;

        &:hover {
            margin-top: -8px;
        }
    }

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        color: white;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 16px;
        font-family: 'Jockey One', cursive;
        padding: 0 10px;
        box-sizing: border-box;
        text-align: center;
        line-height: 1;
        background: linear-gradient(
            0deg,
            rgba(0,0,0,.3),
            rgba(0,0,0,0) 50%,
            rgba(0,0,0,0) 92%,
            rgba(0,0,0,.08) 92%,
            rgba(0,0,0,.08)
        )
    }

    li:nth-of-type(1) {
        background: RGB(90, 0, 150);
    }
    
    li:nth-of-type(2) {
        background: RGB(251, 90, 8);
    }
    
    li:nth-of-type(3) {
        background: RGB(253, 177, 9);
    }
    
    li:nth-of-type(4) {
        background: RGB(120, 120, 6);
    }
    
    li:nth-of-type(5) {
        background: RGB(228, 0, 20);
    }
    
    li:nth-of-type(6) {
        background: RGB(0, 20, 75);
    }
}